const filterSidebar = {
    urlParams: new URLSearchParams(window.location.search),
    init: function () {
        const filterForm = $('#filterForm');
        const filterSubmitBtn = $('.filter-sidebar-submit > button');
        const filterResetBtn = $('[data-filter-reset]');
        const checkboxes = $('.filter-form-check-input');

        if (!filterForm.length || !checkboxes.length) {
            return;
        }

        filterSubmitBtn.on('click', function () {
            filterForm.trigger('submit');
        });

        filterResetBtn.on('click', function () {
            checkboxes.each(function () {
                filterSidebar.urlParams.delete($(this).attr('name'));
            });

            filterSidebar.change()
        });

        checkboxes.each(function () {
            if (filterSidebar.urlParams.has($(this).attr('name'))) {
                $(this).prop('checked', true);
            }
        });

        filterForm.on('submit', function (event) {
            event.preventDefault();

            checkboxes.each(function () {
                const filterName = $(this).attr('name');

                if ($(this).prop('checked')) {
                    filterSidebar.urlParams.set(filterName, 'on');

                    return;
                }

                filterSidebar.urlParams.delete(filterName);
            });

            filterSidebar.change()
        });
    },
    change: function() {
        if (filterSidebar.urlParams.has('page') && Number.isInteger(filterSidebar.urlParams.get('page'))) {
            filterSidebar.urlParams.delete('page');
        }

        if (filterSidebar.urlParams.has('pageNum')) {
            filterSidebar.urlParams.delete('pageNum');
        }

        window.location = window.location.pathname + '?' + this.urlParams.toString();
    }
};

$(document).ready(function () {
    filterSidebar.init();
});
