import Swiper from 'swiper';
import { Autoplay, Mousewheel, Navigation, Pagination } from 'swiper/modules';

$(document).ready(function () {
    const productContent = $('.product-content-variables');
    const productForm = productContent.find('form#product-form');

    if (!productForm.length) {
        return;
    }

    const colorSelector = 'input[name="color"]';
    const sizeSelector = 'input[name="size"]';

    const hasColors = productForm.find(colorSelector).length > 0;
    const hasSizes = productForm.find(sizeSelector).length > 0;

    let colorId, colorName;
    let sizeId, sizeName;

    let mobileSlider;

    const getFormData = () => {
        const formData = {};

        productForm.serializeArray().forEach((item) => {
            formData[item.name] = item.value;
        });

        return formData;
    };

    const isFormValid = () => {
        const formData = getFormData();

        if (hasColors && (typeof formData.color === 'undefined' || !formData.color)) {
            return false;
        }

        return !(hasSizes && (typeof formData.size === 'undefined' || !formData.size));
    };

    const getActiveColorId = () => {
        const colorInputEl = productForm.find(`${ colorSelector }:checked`);

        return String(colorInputEl.length ? colorInputEl.val() : 0);
    };

    const toggleShowcase = () => {
        const colorId = getActiveColorId();

        $('[data-image-color]').addClass('d-none');
        $(`[data-image-color="${ colorId }"`).removeClass('d-none');

        $('.product-showcase-mobile').find('[data-image-color]').each(function () {
            if ($(this).data('image-color').toString() === colorId) {
                $(this).addClass('swiper-slide');

                return;
            }

            $(this).removeClass('swiper-slide swiper-slide-active swiper-slide-prev swiper-slide-next');
        });

        if (!mobileSlider) {
            console.log('no mobile slider');
            return;
        }

        mobileSlider.update();
    };

    const toggleSubmitButton = () => {
        const button = $('button.product-content-submit');

        button.prop('disabled', !isFormValid());
    };

    const toggleSizeList = (hide) => {
        const sizeList = productForm.find('.product-content-sizes-wrap');

        if (hide) {
            sizeList.addClass('d-none');

            return;
        }

        sizeList.removeClass('d-none');
    };

    const toggleSizeItems = (hide) => {
        const sizeItems = productForm.find('[data-size-color]');

        if (hide) {
            sizeItems.addClass('d-none');

            return;
        }

        sizeItems.removeClass('d-none');
    };

    const toggleAvailability = (hide) => {
        const productAvailableEl = $('.product-content-available');

        if (hide) {
            productAvailableEl.addClass('d-none');

            return;
        }

        productAvailableEl.removeClass('d-none');
    };

    const toggleUnavailability = (hide) => {
        const productUnavailableEl = $('.product-content-unavailable');

        if (hide) {
            productUnavailableEl.addClass('d-none');

            return;
        }

        productUnavailableEl.removeClass('d-none');
    };

    const toggleStockNotification = (hide, showUnavailability) => {
        const stockNotificationEl = $('.product-notification');

        if (hide) {
            stockNotificationEl.addClass('d-none');

            toggleUnavailability(!showUnavailability);

            return;
        }

        stockNotificationEl.removeClass('d-none');

        const colorIdEl = $(stockNotificationEl).find('[data-color-value]');
        const colorNameEl = $(stockNotificationEl).find('[data-color-name]');

        if (!colorName) {
            colorNameEl.parent().addClass('d-none');
        } else {
            colorIdEl.val(colorId);
            colorNameEl.html(colorName).parent().removeClass('d-none');
        }

        const sizeIdEl = $(stockNotificationEl).find('[data-size-value]');
        const sizeNameEl = $(stockNotificationEl).find('[data-size-name]');

        if (!sizeName) {
            sizeNameEl.parent().addClass('d-none');
        } else {
            sizeIdEl.val(sizeId);
            sizeNameEl.html(sizeName).parent().removeClass('d-none');
        }

        toggleUnavailability(true);
    };

    productForm
        .on('submit', (e) => isFormValid() || e.preventDefault())
        .on('change', colorSelector, function () {
            colorId = $(this).val();
            colorName = $(this).data('color-name');

            sizeId = sizeName = null;

            $('#product-form-color').html(colorName || 'Unknown');
            $('#product-form-size').html('Please select a color below');

            productForm.find(`${ sizeSelector }:checked`)
                .prop('checked', false)
                .trigger('change');

            toggleSizeItems(true);

            productForm.find(`[data-size-color="${ colorId }"]`).removeClass('d-none');

            toggleSizeList();

            toggleShowcase();
            toggleSubmitButton();

            toggleStockNotification(true);
            toggleAvailability(hasSizes);
            toggleUnavailability(true);
        })
        .on('click', '.product-content-color > label', function () {
            const inputEl = $(this).parent().find(colorSelector);
            const isDisabled = inputEl.prop('disabled');

            colorId = inputEl.val();
            colorName = inputEl.data('color-name');

            if (!isDisabled) {
                return;
            }

            $(colorSelector).prop('checked', false);
            $('#product-form-color').html(colorName || 'Unknown');

            toggleSizeItems(true);
            toggleSizeList(true);
            toggleStockNotification(true);
            toggleAvailability(true);
            toggleUnavailability();
            toggleSubmitButton();
        })
        .on('change', sizeSelector, function () {
            sizeId = $(this).val();
            sizeName = $(this).data('size-name');

            if (!$(this).is(':checked')) {
                sizeName = 'Please select a color below';
            }

            $('#product-form-size').html(sizeName);

            toggleStockNotification(true);
            toggleSubmitButton();
        })
        .on('click', '.product-content-sizes-item > label', function () {
            const inputEl = $(this).parent().find(sizeSelector);
            const isDisabled = inputEl.prop('disabled');

            sizeId = inputEl.val();
            sizeName = inputEl.data('size-name');

            if (!isDisabled) {
                return;
            }

            $(sizeSelector).prop('checked', false);
            $('#product-form-size').html(sizeName || 'Unknown');

            toggleStockNotification(true);
            toggleAvailability(true);
            toggleUnavailability();
            toggleSubmitButton();
        });

    productContent
        .on('click', '.product-content-unavailable a', function (e) {
            e.preventDefault();

            toggleStockNotification();
        })
        .on('click', '.product-notification #product-notification-dismiss', function (e) {
            e.preventDefault();

            toggleStockNotification(true, true);
        });

    toggleShowcase();
    toggleSubmitButton();

    productContent.on('submit', 'form.product-notification', function (e) {
        e.preventDefault();

        const email = $(this).find('input[name="email"]').val();

        if (!isValidEmail(email)) {
            showNotification('Incorrect email!', 'danger', 'fa-circle-xmark');

            return;
        }

        $.ajax({
            url: $(this).attr('action'),
            method: 'post',
            data: $(this).serialize(),
            dataType: 'json',
        })
            .done(function () {
                showNotification(
                    'We\'ll let you know as soon as this product is back in stock!',
                    'success',
                    'fa-check-circle',
                );
            })
            .fail(function () {
                showNotification(
                    'Oops! Something went wrong. Please try again in a moment.',
                    'danger',
                    'fa-circle-xmark',
                );
            })
            .always(function () {
                toggleStockNotification(true, true);
            });
    });

    function isValidEmail(email) {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    }

    const sizingGuideLink = document.querySelector('.product-content-sizes-guide a');

    if (sizingGuideLink) {
        sizingGuideLink?.addEventListener('click', function (event) {
            event.preventDefault(); // Prevent default link behavior

            // Extract the data-page value from the anchor element
            const pageUrl = sizingGuideLink.getAttribute('data-page');

            // Set the iframe src to the extracted page URL
            const sizingGuideIframe = document.getElementById('sizingGuideIframe');
            sizingGuideIframe.src = pageUrl;

            // Show the modal
            const sizingGuideModal = new bootstrap.Modal(document.getElementById('sizingGuideModal'));
            sizingGuideModal.show();
        });
    }

    if (!$('.product-showcase-mobile .swiper-mobile')) {
        return;
    }

    mobileSlider = new Swiper('.product-showcase-mobile .swiper-mobile', {
        modules: [Autoplay, Mousewheel, Navigation, Pagination],
        slidesPerView: 1,
        pagination: {
            el: '.swiper-pagination',
            type: 'fraction',
        },
        navigation: {
            nextEl: '.swiper-mobile-next',
            prevEl: '.swiper-mobile-prev',
        },
        autoplay: {
            delay: 5000,
        },
    });
});